<template>
  <v-container fluid>
    <h2>Teamchallenges</h2>

    <v-card>
      <v-data-table
        :items="tcs"
        :headers="[
          { text: 'Name', value: 'name', sortable: false },
          { text: 'Modus', value: 'mode', sortable: false },
          { text: 'Gender', value: 'gender', sortable: false },
          { text: 'Teams', value: 'teams', sortable: false, align: 'center' },
        ]"
        :mobile-breakpoint="0"
        :items-per-page="-1"
        hide-default-footer
        @click:row="open"
      >
        <template #item.teams="{item}">
          {{ (item.teams || []).length }}
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { deleteQuery, updateQuery, useGraphQL } from '@/plugins/graphql'
import { mapGetters } from 'vuex'
import gql from 'graphql-tag'

const query = `
  _id
  identifiers { name value }
  type
  ... on StbLtf2024Tc {
    name mode gender
    teams {
      club { _id name }
      identifiers { name value }
    }
  }
`

export default {
  name: 'tcs',

  setup (props, context) {
    return {
      ...useGraphQL(context)
    }
  },

  props: {
    id: {
      type: String,
      required: true
    }
  },

  data: () => ({
    EventFind: [],
    sync: {
      tc: {
        in_progress: false
      }
    }
  }),

  computed: {
    ...mapGetters(['loading']),
    tcs () {
      return this.EventFind.slice().sort((a, b) => a.name < b.name ? -1 : 1)
    }
  },

  methods: {
    open (item) {
      this.$router.push(`/input/${item._id}`)
    }
  },

  apollo: {
    EventFind: {
      query: gql`
           query($parent: UUID!) { EventFind(parent: $parent) { ${query} }}
      `,
      variables () {
        return {
          parent: this.id
        }
      },
      subscribeToMore: {
        document: gql`
            subscription($parent: UUID!) { EventCreate(parent: $parent) { ${query} }}
        `,
        variables () {
          return {
            parent: this.id
          }
        },
        updateQuery: updateQuery('EventFind', 'EventCreate')
      }
    },
    $subscribe: {
      EventUpdate: {
        query: gql`
            subscription($parent: UUID!) { EventUpdate(parent: $parent) { ${query} }}
        `,
        variables () {
          return {
            parent: this.id
          }
        }
      },
      EventDelete: {
        query: gql`
            subscription($parent: UUID!) { EventDelete(parent: $parent) }
        `,
        variables () {
          return {
            parent: this.id
          }
        },
        result (id) {
          deleteQuery('EventFind', 'EventDelete', this.EventFind, id)
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
